<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="arOffers"
      :loading="bLoading"
      dense
      disable-sort
      hide-default-footer
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title v-text="$t('product.prices')" />
          <v-spacer />
          <v-btn
            :disabled="drawer"
            :to="offerBtnPath"
            color="primary"
            depressed
            small
          >
            <icon-add />
            {{ $t("new.offer") }}
          </v-btn>
        </v-toolbar>
      </template>

      <template #[`item.pricelist`]="{ item }">
        <template v-if="getPriceList(item.price_list_id)">
          <v-btn
            v-if="item.id"
            :to="{
              name: 'products.update.offer.update',
              params: { offer_id: item.id },
            }"
            color="primary"
            text
          >
            <span v-text="getPriceList(item.price_list_id, 'name')" />
          </v-btn>
          <span v-else v-text="getPriceList(item.price_list_id, 'name')" />
        </template>
      </template>

      <template #[`item.price`]="{ item }">
        <price-viewer
          :currency-code="item.currency_code"
          :currency-id="item.currency_id"
          :value="getPrice(item)"
        />
      </template>

      <template #[`item.currency_id`]="{ item }">
        <currency-preview :currency-id="item.currency_id" />
      </template>
    </v-data-table>

    <sheet-drawer v-model="drawer" :lg="75" :md="80" :xl="50" @close="cancel">
      <v-form v-if="product && drawer" class="fill-height">
        <gw-form-observer :loading="isLoading" @cancel="cancel" @save="save">
          <offer-field v-model="offer" />
        </gw-form-observer>
      </v-form>
    </sheet-drawer>
  </v-container>
</template>
<script lang="ts">
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import type { DataOptions } from "vuetify";
import type { Location, RawLocation, Route } from "vue-router";
import VueRouter from "vue-router";
import {
  Offer as OfferModel,
  Product,
} from "@planetadeleste/vue-mc-shopaholic";
import type {
  OfferData,
  PriceList,
  PriceListCollection,
} from "@planetadeleste/vue-mc-gw";

import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import OfferField from "@/components/form/fields/Offer.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

@Component({
  components: {
    SheetDrawer,
    OfferField,
    CurrencyPreview,
    PriceViewer,
  },
})
export default class Offers extends Mixins(GlobalMixin, DataTableMixin) {
  drawer = false;
  routeFrom: Route | null = null;
  offer: OfferModel = new OfferModel();

  @VModel({ type: Object, default: () => new Product() }) product!: Product;
  @Prop(Boolean) readonly bLoading!: boolean;
  @Prop([Array, Object]) readonly priceList!: PriceListCollection;

  pagination: DataOptions = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
  };

  get actionPath() {
    const path = this.routeFrom ? this.routeFrom.path : this.$route.path;
    return `${path}/offer`;
  }

  get offerBtnPath() {
    const sRoute = this.$route.name;
    const sName = ["offer", "list"].some((sVal) => sRoute?.includes(sVal))
      ? "products.create"
      : sRoute;
    const obRoute: Location = { name: `${sName}.offer.create` };

    if (this.$route.params.id) {
      obRoute.params = this.$route.params;
    }

    return obRoute;
  }

  get arOffers(): OfferData[] {
    return this.product ? this.product.get("offers") : [];
  }

  @Watch("$route", { immediate: true })
  onRouteChanged(to: Route, from: Route) {
    if (to.name) {
      if (
        [
          "products.update.offer.update",
          "products.update.offer.create",
          "products.create.offer.create",
        ].includes(to.name)
      ) {
        const offerData =
          this.$_.find(this.product.offers, {
            id: Number(to.params.offer_id),
          }) || {};

        this.offer = new OfferModel(offerData);
        this.routeFrom = from;
        this.drawer = true;
        return;
      }
    }

    this.drawer = false;
  }

  mounted() {
    this.$nextTick(() => {
      const headers: DataTableHeader[] = [
        { text: "pricelist", value: "pricelist" },
        { text: "currency", value: "currency_id" },
        {
          text: "price",
          value: "price",
          class: "mw-150",
        },
        {
          text: "actions",
          value: "actions",
          align: "end",
          class: "mw-150",
        },
      ];
      this.setDTHeaders(headers);
    });
  }

  async save() {
    const arOffers = this.product.offers || [];
    this.offer.sync();

    // If product exists, store offer directly
    if (this.product.id) {
      this.offer.product_id = this.product.id;

      this.loading();
      const response = await this.offer
        .store()
        .then((response) => response?.getData())
        .catch((reason) => {
          this.evalResponse(reason);
        });

      if (response) {
        this.evalResponse(response);
        if (response.status && this.$_.has(response, "data.id")) {
          this.offer.set(response.data);
        }
      }

      this.loaded();
      this.$emit("reload-offers");

      // await this.product.updateOffers();
    } else {
      // @ts-ignore
      const obOfferData: OfferData = this.offer.toJSON();

      if (this.offer.id) {
        const iOfferIndex = this.$_.findIndex(arOffers, {
          id: Number(this.offer.id),
        });

        if (iOfferIndex !== -1) {
          this.$set(arOffers, iOfferIndex, obOfferData);
        } else {
          arOffers.push(obOfferData);
        }
      } else {
        arOffers.push(obOfferData);
      }

      this.product.set("offers", arOffers);
      this.product.sync("offers");

      this.$emit("input", this.product);
    }

    this.cancel();
  }

  async removeOffer(iOfferID: number) {
    const arOffers = this.product.offers;
    this.offer.clear();
    const iOfferIndex = this.$_.findIndex(arOffers, { id: iOfferID });

    if (iOfferIndex === -1) {
      return;
    }

    this.offer.set({ id: iOfferID });

    const sMessage = this.$_.toString(this.$t("ask.remove.record"));
    const bRes = await this.$confirm(sMessage, { color: "warning" });

    if (bRes === true) {
      const obResponse = await this.offer.delete();
      this.evalResponse(obResponse?.getData());

      if (this.product.id) {
        await this.product.updateOffers();
      } else {
        arOffers.splice(iOfferIndex, 1);
        this.product.set("offers", arOffers);
      }

      this.$emit("input", this.product);
      this.cancel();
    }
  }

  async cancel() {
    this.offer.clear();
    let obRouteData: RawLocation = { name: "products.create" };

    if (this.$route.params.id) {
      obRouteData = {
        name: "products.update",
        params: { id: this.$route.params.id },
      };
    }

    await this.$router.push(obRouteData).catch((failure) => {
      if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
        return;
      }
    });
  }

  getPriceList(
    iPriceListId: number,
    sKey?: string
  ): PriceList | undefined | any {
    const obPriceList =
      this.priceList && this.priceList.length
        ? this.priceList.find({ id: iPriceListId })
        : undefined;

    if (sKey && obPriceList) {
      return obPriceList.get(sKey, null);
    }

    return obPriceList;
  }

  getPrice(obData: Partial<OfferData>): number | undefined {
    if (!obData.price_list_id) {
      return obData.price_value;
    }

    const obPriceList = this.getPriceList(obData.price_list_id) as PriceList;
    return obPriceList && obPriceList.price_with_tax
      ? obData.price_with_tax_value
      : obData.price_value;
  }
}
</script>
